import {Injectable} from '@angular/core';
import {environment} from '../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {UsuarioPermiso} from './models/usuario-permiso';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  base = environment.base;
  private auth = new BehaviorSubject(null);
  currentAuth = this.auth.asObservable();

  dato: any;

  constructor(private http: HttpClient) {
  }

  load(): Promise<any> {
    /*return new Promise( (resolve, reject) => {
      this.http.get(`${this.base}get-permisos-url-user`).subscribe(data => {
      this.dato = data;
      resolve();
     });
    });*/
    return this.http.get(`${this.base}get-permisos-url-user`).pipe(
      tap( (data) => {
        // console.log(data);
        this.dato = data;
      })
    ).toPromise();
  }

  setAuth(data) {
    this.auth.next(data);
  }

  checkUniqueCuentaUsuario(cuenta: string) {
    return this.http.post(`${this.base}check-unique-cuenta-usuario`, {cuenta});
  }

  login(data: any) {
    return this.http.post(`${this.base}login`, data)
      .pipe(
        map((success: any) => {
          if (data.remember) {
            if (localStorage.getItem('usuario-cuenta') === null) {
              if (success.usuario.usuario_foto) {
                const usuarioFoto = `${this.base}user-download/${success.usuario.usuario_foto.split('/')[1]}`;
                localStorage.setItem('usuario-foto', btoa(usuarioFoto));
              } else {
                if (localStorage.getItem('usuario-foto') !== null) {
                  localStorage.removeItem('usuario-foto');
                }
              }
              localStorage.setItem('usuario-cuenta', btoa(success.usuario.usuario_cuenta));
            }
          } else {
            if (localStorage.getItem('usuario-cuenta') !== null) {
              localStorage.removeItem('usuario-cuenta');
              localStorage.removeItem('usuario-foto');
            }
          }
          const tokenAF = `Bearer ${success.token}`;
          localStorage.setItem('token-activos', btoa(tokenAF));
          return success;
        })
      );
  }

  signup(data: any) {
    return this.http.post(`${this.base}signup`, data);
  }

  logout() {
    localStorage.removeItem('token-activos');
    return new Observable((observer) => {
      observer.next(true);
      observer.complete();
    });
  }

  getUser() {
    return this.http.post(`${this.base}get-user`, null);
  }

  /*getUser() {
    if (this.logged()) {
      return this.http.post(`${this.base}get-user`, null);
    } else {
      const response = {
        error: true
      };
      return new Observable((observer) => {
        observer.next(response);
        observer.complete();
      });
    }
  }*/

  logged() {
    return localStorage.getItem('token-activos') !== null;
  }

  /*
  * Usuarios
  * */
  paginate(items: number, page: number) {
    return this.http.get(`${this.base}users-paginate/${items}?page=${page}`);
  }

  paginateURL(url: string) {
    return this.http.get(url);
  }

  search(data: any) {
    return this.http.post(`${this.base}users-search`, data);
  }

  show(id) {
    return this.http.get(`${this.base}users/${id}`);
  }

  update(data: any, id: number) {
    return this.http.post(`${this.base}update-users/${id}`, data);
  }

  destroy(id) {
    return this.http.delete(`${this.base}users/${id}`);
  }

  updatePassword(data: { password: string, password_actual: string }) {
    return this.http.post(`${this.base}update-password`, data);
  }

  getPermisosUser(id: number) {
    return this.http.get(`${this.base}get-permisos-user/${id}`);
  }

  getPermisosUrlUser() {
    return this.http.get(`${this.base}get-permisos-url-user`);
  }

  updateUsuarioPermisos(usuarioPermisos: UsuarioPermiso[], id: number) {
    return this.http.post(`${this.base}update-usuario-permisos/${id}`, {usuarioPermisos});
  }

  haveAccess(urlPermiso: string) {
    return this.http.post(`${this.base}have-access`, {urlPermiso});
  }

  getRevaluos() {
    return this.http.get(`${this.base}get-revaluos`);
  }

  /*
  * end usuarios
  * */
}
