import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {faLock, faSpinner, faSyncAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.reducer';
import {login} from '../store/actions/auth.actions';
import {AuthState} from '../store/reducers/auth.reducer';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'activos-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  authState: AuthState;
  loginGroup: FormGroup;

  userImage = 'assets/images/gador.png';
  userCuenta = null;

  faUser = faUser;
  faLock = faLock;
  faSyncAlt = faSyncAlt;
  faSpinner = faSpinner;

  constructor(private fb: FormBuilder,
              private router: Router,
              private spinner: NgxSpinnerService,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.store.subscribe((response: AppState) => {
      this.authState = response.auth;
    });
    localStorage.removeItem('token-activos');

    if (localStorage.getItem('usuario-foto') !== null) {
      this.userImage = atob(localStorage.getItem('usuario-foto'));
    }
    if (localStorage.getItem('usuario-cuenta') !== null) {
      this.userCuenta = atob(localStorage.getItem('usuario-cuenta'));
    }
    this.authState.logged = false;

    this.loginGroup = this.fb.group({
      usuario_cuenta: new FormControl(this.userCuenta === null ? '' : this.userCuenta, [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(false)
    });

    this.store.subscribe((response: { auth: AuthState }) => {
      if (response.auth.logged === true) {
      } else {
        if (this.authState.error) {
          if (localStorage.getItem('usuario-cuenta') !== null) {
            this.loginGroup.patchValue({
              password: ''
            });
          } else {
            this.loginGroup.reset();
          }
        }
      }
      if (localStorage.getItem('usuario-cuenta') !== null) {
        this.loginGroup.patchValue({
          remember: true
        });
        setTimeout(() => {
          const password: any = document.getElementById('password');
          if (password !== null) {
            password.focus();
          }
        }, 200);
      } else {
        this.loginGroup.patchValue({
          remember: false
        });
        setTimeout(() => {
          const cuenta: any = document.getElementById('cuenta');
          if (cuenta !== null) {
            cuenta.focus();
          }
        }, 200);
      }
    });
  }

  onLogin() {
    this.store.dispatch(login(this.loginGroup.value));
  }

  /*  login() {
      this.authService.login(this.loginGroup.value)
        .subscribe((res: any) => {
          this.router.navigate(['/admin']);
          this.toastr.success('Accediendo al sistema', 'Exito');
        }, (error: any) => {
          this.loginGroup.reset();
          this.toastr.error('Vuelva a ingresar sus credenciales', 'Credenciales incorrectas');
        });
    }*/
}
