import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthService} from '../../auth.service';
import {login, loginFailure, loginSuccess, setAuth, setAuthSuccess} from '../actions/auth.actions';
import {Usuario} from '../../models/usuario';
import {Router} from '@angular/router';
import {updatePassUsuario, updatePassUsuarioFailure, updatePassUsuarioSuccess} from '../actions/usuario.actions';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(login),
        switchMap((props: { usuario_cuenta: string, password: string, remember: boolean }) => {
          return this.authService.login(props)
            .pipe(
              map((response: { token: string, usuario: Usuario }) => {
                if (response.usuario.rol_nombre === 'REVALUADOR') {
                  if (response.usuario.revaluo_habilitado !== null) {
                    response.usuario.revaluo_habilitado ?
                      this.router.navigate(['/revaluo'])
                      : this.router.navigate(['/']);
                  }
                } else {
                  this.router.navigate(['/admin']);
                }
                return loginSuccess(response);
              }),
              catchError(error => of(loginFailure(error)))
            );
        })
      )
  );

  setAuth$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(setAuth),
        switchMap(() => {
          return this.authService.getUser()
            .pipe(
              map((response: {
                success: Usuario
              }) => {
                return setAuthSuccess({
                  usuario: response.success
                });
              }),
              catchError(error => {
                this.router.navigate(['/login']);
                return of(loginFailure(error));
              })
            );
        })
      )
  );

  updatePassUsuario$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(updatePassUsuario),
        switchMap((props: { password: string, password_actual: string }) => {
          return this.authService.updatePassword(props)
            .pipe(
              map((response: Usuario) => {
                return updatePassUsuarioSuccess({
                  usuario: response
                });
              }),
              catchError(error => of(updatePassUsuarioFailure({error})))
            );
        })
      )
  );


  constructor(private actions$: Actions,
              private router: Router,
              private authService: AuthService) {
  }

}
