export const environment = {
  production: true,
  base2: 'http://54.85.52.2/sistemas/gador-activos-fijos/servidor/public/api/',
  base1: 'http://72.52.185.132/siafgo/api/',
  base7: 'https://siafgo2.sistemahard.com/api/',
  base6: 'https://siafgo.sistemahard.com/api/',
  base: 'https://apisiafgo.oruro.gob.bo/api/',
  base9: 'http://localhost/servidor/public/api/',
  base0: 'http://192.168.1.10:8000/api/',
  base4: 'http://192.168.129.218/servidor/public/api/',
  base3: 'http://192.168.128.28:81/servidor/public/api/'
};
