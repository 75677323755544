import {createAction, props} from '@ngrx/store';
import {Paginate} from '../../models/paginate';
import {Usuario} from '../../models/usuario';
import {UsuarioPermiso} from '../../models/usuario-permiso';
import {Revaluo} from '../../models/revaluo';

export const paginateUsuarios = createAction(
  '[Usuario] Paginate Usuarios',
  props<{ items: number, page: number }>()
);

export const paginateUsuariosSuccess = createAction(
  '[Usuario] Paginate Usuarios Success',
  props<{ usuarios: Usuario[], paginacion: Paginate }>()
);

export const paginateUsuariosFailure = createAction(
  '[Usuario] Paginate Usuarios Failure',
  props<{ error: any }>()
);

export const searchUsuario = createAction(
  '[Usuario] Search Usuario',
  props<{ data: string, items: number }>()
);

export const searchUsuarioSuccess = createAction(
  '[Usuario] Search Usuario Success',
  props<{ usuarios: Usuario[], paginacion: Paginate }>()
);

export const searchUsuarioFailure = createAction(
  '[Usuario] Search Usuario Failure',
  props<{ error: any }>()
);

export const irUsuarioLocation = createAction(
  '[Usuario] ir Usuario Location',
  props<{ location: string  }>()
);

export const createUsuario = createAction(
  '[Usuario] Create Usuario'
);

export const storeUsuario = createAction(
  '[Usuario] Store Usuario',
  props<{ usuario: Usuario | any }>()
);

export const storeUsuarioSuccess = createAction(
  '[Usuario] Store Usuario Success',
  props<{ usuario: Usuario, token: string }>()
);

export const storeUsuarioFailure = createAction(
  '[Usuario] Store Usuario Failure',
  props<{ error: any }>()
);
export const destroyUsuario = createAction(
    '[Usuario] Destroy Usuario',
    props<{ usuarioId: number }>()
);
export const destroyUsuarioSuccess = createAction(
    '[Usuario] Destroy Usuario Success',
    props<{ usuarioId: number }>()
);

export const destroyUsuarioFailure = createAction(
    '[Usuario] Destroy Usuario Failure',
    props<{ error: any }>()
);

export const editUsuario = createAction(
  '[Usuario] Edit Usuario',
  props<{ usuario: Usuario }>()
);

export const permisosUsuario = createAction(
  '[Usuario] Permisos Usuario',
  props<{ usuario: Usuario }>()
);

export const updateUsuario = createAction(
  '[Usuario] Update Usuario',
  props<{ usuario: any, idusuario: number }>()
);

export const updateUsuarioSuccess = createAction(
  '[Usuario] Update Usuario Success',
  props<{ usuario: Usuario | any}>()
);

export const updateUsuarioFailure = createAction(
  '[Usuario] Update Usuario Failure',
  props<{ error: any }>()
);

export const updateUsuarioPermiso = createAction(
  '[Usuario] Update Usuario Permiso',
  props<{ idusuario: number, usuario_permisos: UsuarioPermiso[] }>()
);

export const updateUsuarioPermisoSuccess = createAction(
  '[Usuario] Update Usuario Permiso Success',
  props<{ usuario: Usuario | any}>()
);

export const updateUsuarioPermisoFailure = createAction(
  '[Usuario] Update Usuario Permiso Failure',
  props<{ error: any }>()
);

export const updatePassUsuario = createAction(
    '[Usuario] Update Password Usuario',
    props<{ password: string, password_actual: string}>()
);

export const updatePassUsuarioSuccess = createAction(
    '[Usuario] Update Password Usuario Success',
    props<{ usuario: Usuario }>()
);

export const updatePassUsuarioFailure = createAction(
    '[Usuario] Update Password Usuario Failure',
    props<{ error: any }>()
);
export const getRevaluos = createAction(
  '[Usuario] Get Revaluos'
);
export const getRevaluosSuccess = createAction(
  '[Usuario] Get Revaluos Success',
  props<{ revaluos: Revaluo[] }>()
);
export const getRevaluosFailure = createAction(
  '[Usuario] Get Revaluos Failure',
  props<{ error: any }>()
);
