import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../auth.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {Store} from '@ngrx/store';
import {AdminState} from '../store/reducers/admin.index';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService,
              private permissionsService: NgxPermissionsService,
              private store: Store<AdminState>,
              private route: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.search('login') === -1) {
      return this.authService.getPermisosUrlUser().pipe(
        map((data: any) => {
          if (data.isResponsable) {
            data.permisosAlias.push('reportes');
            data.permisosAlias.push('reportes-individual');
            data.permisosAlias.push('perfil-responsable');
          }
          // console.log(data);
          this.permissionsService.loadPermissions(data.permisosAlias);
          /*this.permissionsService.permissions$.subscribe((dato: any) => {
            console.log(dato);
          });*/
          return true;
        }));
    } else {
      this.permissionsService.flushPermissions();
      return true;
    }
  }
}
