import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Interceptor} from './interceptor';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeEsBo from '@angular/common/locales/es-BO';
import {ToastrModule} from 'ngx-toastr';
import {AuthService} from './auth.service';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxNavbarModule} from 'ngx-bootstrap-navbar';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {StoreModule} from '@ngrx/store';
import {appReducers} from './store/app.reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment.prod';
import {EffectsModule} from '@ngrx/effects';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {AuthEffects} from './store/effects/auth.effects';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {NgxPermissionsModule} from 'ngx-permissions';


registerLocaleData(localeEsBo, 'es-Bo');

/*export function loadPermissions(authService: AuthService, ps: NgxPermissionsService) {
  return () => authService.load().then( (data: any) => {
    return ps.loadPermissions(data);
  });
}*/

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ButtonsModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    FontAwesomeModule,
    NgxNavbarModule,
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([AuthEffects]),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    NgxPermissionsModule.forRoot()
  ],
  providers: [AuthService, {
    provide: LOCALE_ID, useValue: 'es-Bo'
  }, {
    provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
  },
    DatePipe,
    /*AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadPermissions,
      deps: [AuthService, NgxPermissionsService],
      multi: true
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
