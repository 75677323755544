import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class Interceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.search('/login') === -1) {
      if (localStorage.getItem('token-activos') !== null) {
        const tokenActivos = atob(localStorage.getItem('token-activos'));
        const customReq = req.clone({
          headers: new HttpHeaders().set('Authorization', tokenActivos)
        });
        return next.handle(customReq);
      } else {
        return next.handle(req);
      }
    } else {
      return  next.handle(req);
    }
  }

}
