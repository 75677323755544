import {Action, createReducer, on} from '@ngrx/store';
import {Usuario} from '../../models/usuario';
import {
  login,
  loginFailure,
  loginSuccess,
  setAuth,
  setAuthFailure,
  setAuthSuccess
} from '../actions/auth.actions';
import {updatePassUsuario, updatePassUsuarioFailure, updatePassUsuarioSuccess} from '../actions/usuario.actions';
import {Revaluo} from '../../models/revaluo';

export const authFeatureKey = 'auth';

export interface AuthState {
  usuario: Usuario;
  revaluos: Revaluo[];
  token: string;
  logged: boolean;
  loaded: boolean;
  loading: boolean;
  message: string;
  error: any;
}

export const initialState: AuthState = {
  usuario: null,
  revaluos: [],
  token: null,
  logged: false,
  loaded: false,
  loading: false,
  message: null,
  error: null
};


const authReducer = createReducer(
  initialState,
  on(login, (state: AuthState, props) => ({
    ...state,
    loading: true,
    loaded: false,
    message: null,
    error: null
  })),
  on(loginSuccess, (state: AuthState, props) => ({
    ...state,
    token: props.token,
    usuario: props.usuario,
    logged: true,
    loading: false,
    loaded: true
  })),
  on(loginFailure, (state: AuthState, props) => ({
    ...state,
    loading: false,
    loaded: false,
    message: 'Credenciales Incorrectas',
    error: props.error
  })),
  on(setAuth, (state: AuthState) => ({
    ...state,
    loading: true
  })),
  on(setAuthSuccess, (state: AuthState, props) => ({
    ...state,
    loading: false,
    logged: true,
    loaded: true,
    usuario: props.usuario
  })),
  on(setAuthFailure, (state: AuthState, props) => ({
    ...state,
    loading: false,
    logged: false,
    loaded: false,
    error: props.error
  })),
  on(updatePassUsuario, (state: AuthState, props) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(updatePassUsuarioSuccess, (state: AuthState, props) => ({
    ...state,
    loading: false,
    loaded: true,
    message: `Usuario ${props.usuario.usuario_cuenta} actualizo su contraseña exitosamente`,
  })),
  on(updatePassUsuarioFailure, (state: AuthState, props) => ({
    ...state,
    loading: false,
    loaded: false,
    message: null,
    error: props.error
  })),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
